<template>
    <div>
        <datepicker v-model="input" v-bind="attributes" :name="name" :input-class="classes" wrapper-class="p-0 border-0" :class="null" @input="handleInput" />

        <template v-for="(errorMessage, index) in errors[name]">
            <v-text :key="'error_message_' + index" type="error">
                {{ errorMessage }}
            </v-text>
        </template>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import FormMixin from "./form-mixin";

export default {
    components: {
        Datepicker,
    },
    mixins: [
        FormMixin,
    ],
    props: {
        name: {
            type: String,
            required: false,
            default: () => null,
        },
        value: {
            type: Date,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            /**
             * The base classes for the component.
             *
             * @var String
             */
            baseClasses: "ring-0 focus:ring-gray-600 focus:outline-none flex-1 block rounded-none border-transparent w-full max-w-full py-4 px-8 disabled:opacity-75 disabled:cursor-not-allowed",

            /**
             * The available component states.
             *
             * @var Object
             */
            states: {
                /**
                 * The classes for when the component is in a default state.
                 */
                default: "bg-gray-100",

                /**
                 * The classes for when the component is readonly.
                 */
                readonly: "bg-transparent",

                /**
                 * The classes for when the component has errors.
                 */
                error: "bg-gray-100 border-red-500 focus:border-red-500 ring-red-500 focus:ring-red-500",
            },
        };
    },
    computed: {
        classes() {
            return `form-date ${this.baseClasses} ${this.states[this.state]}`;
        },
    },
};
</script>
